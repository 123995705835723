import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UiButton from "../../../components/button/Button";
import { Form } from "react-final-form";
import UiFieldMessage from "../../../components/field-message/FieldMessage.component";
import { FieldMessageSeverity } from "../../../models/components/field-message.model";
import { useState } from "react";
import { ParticipantCreateForm } from "../../../models/pages/participants-row.model";
import { FormApi } from "final-form";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { AxiosError } from "axios";
import UiInputText from "../../../components/input-text/InputText.component";
import { Validations } from "../../../services/form/validations.service";
import UiSelect from "../../../components/select/Select.component";
import { ListOption } from "../../../models/misc.model";
import { useInstitutionsDetailed } from "../../../hooks/useInstitutionsDetailed";
import {
	Gender,
	PreferredLanguage,
	eParticipantStatus,
} from "../../../models/entities/participant.model";
import UiInputDate from "../../../components/input-date/InputDate.component";
import { Formatter } from "../../../services/formatter/formatter.service";
import { AuthRoles } from "../../../models/roles.model";
import FormSection from "../../../components/form-section/FormSection.component";

const ParticipantsCreateModal = ({
	isVisible,
	onClose,
}: {
	isVisible: boolean;
	onClose: () => void;
}) => {
	const { t } = useTranslation("common");
	const navigate = useNavigate();

	const [message, setMessage] = useState<boolean>(false);
	const institutions = useInstitutionsDetailed();

	const [formData, setFormData] = useState<ParticipantCreateForm>({
		firstName: null,
		lastName: null,
		institutionId: null,
		status: null,
		dateOfBirth: null,
		gender: null,
		languagePref: null,
		email: null,
		phoneNumber: null,
	});

	// Submit
	const handleSubmit = async (
		data: ParticipantCreateForm,
		form: FormApi<ParticipantCreateForm, ParticipantCreateForm>
	) => {
		setMessage(false);
		await EndpointsService.dataRetriever
			.createParticipant({
				body: {
					institutionId: formData.institutionId!,
					status: eParticipantStatus.PROSPECTIVE,
					firstName: formData.firstName!,
					lastName: formData.lastName!,
					dateOfBirth: formData.dateOfBirth
						? Formatter.dateUTCWithoutTime(formData.dateOfBirth)!.toISOString()
						: null,
					gender: formData.gender,
					languagePref: formData.languagePref,
					email: formData.email,
					phoneNumber: formData.phoneNumber,
					roleId: AuthRoles.PARTICIPANT,
					country: "United States of America",
				},
			})
			.then((response) => navigate(`/participant/${response.id}/details`))
			.catch((error: AxiosError) => setMessage(true));
	};

	return (
		<Dialog
			header={t("PARTICIPANTS.DASHBOARD.MODAL_CREATE.TITLE")}
			visible={isVisible}
			breakpoints={{ "960px": "75vw", "640px": "100vw" }}
			style={{ width: "70vw" }}
			modal={true}
			focusOnShow={false}
			onHide={onClose}
		>
			<Form
				onSubmit={handleSubmit}
				initialValues={formData}
				render={({ handleSubmit, form, submitting }) => (
					<form onSubmit={handleSubmit}>
						<FormSection>
							<div className="row">
								<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-5">
									<UiInputText
										id="firstName"
										placeholder="PARTICIPANTS.DASHBOARD.MODAL_CREATE.FORM_FIELDS.firstName"
										label="PARTICIPANTS.DASHBOARD.MODAL_CREATE.FORM_FIELDS.firstName"
										name="firstName"
										onChange={(e) =>
											setFormData({ ...formData, firstName: e.target.value })
										}
										validations={[Validations.required]}
										disabled={submitting}
									/>
								</div>

								<div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-5">
									<UiInputText
										id="lastName"
										placeholder="PARTICIPANTS.DASHBOARD.MODAL_CREATE.FORM_FIELDS.lastName"
										label="PARTICIPANTS.DASHBOARD.MODAL_CREATE.FORM_FIELDS.lastName"
										name="lastName"
										onChange={(e) =>
											setFormData({ ...formData, lastName: e.target.value })
										}
										validations={[Validations.required]}
										disabled={submitting}
									/>
								</div>

								<div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
									<UiInputDate
										id="dateOfBirth"
										placeholder="PARTICIPANTS.DASHBOARD.MODAL_CREATE.FORM_FIELDS.dateOfBirth"
										label="PARTICIPANTS.DASHBOARD.MODAL_CREATE.FORM_FIELDS.dateOfBirth"
										name="dateOfBirth"
										onChange={(e) =>
											setFormData({
												...formData,
												dateOfBirth: e.target.value as Date,
											})
										}
										disabled={submitting}
									/>
								</div>

								<div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
									<UiSelect
										id="gender"
										placeholder="PARTICIPANTS.DASHBOARD.MODAL_CREATE.FORM_FIELDS.gender"
										label="PARTICIPANTS.DASHBOARD.MODAL_CREATE.FORM_FIELDS.gender"
										name="gender"
										onChange={(e) =>
											setFormData({ ...formData, gender: e.target.value })
										}
										options={[
											{ id: Gender.F, label: `ENUMS.GENDER.${Gender.F}` },
											{ id: Gender.M, label: `ENUMS.GENDER.${Gender.M}` },
											{
												id: Gender.OTHER,
												label: `ENUMS.GENDER.${Gender.OTHER}`,
											},
										]}
										disabled={submitting}
									/>
								</div>

								<div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
									<UiSelect
										id="languagePref"
										placeholder="PARTICIPANTS.DASHBOARD.MODAL_CREATE.FORM_FIELDS.languagePref"
										label="PARTICIPANTS.DASHBOARD.MODAL_CREATE.FORM_FIELDS.languagePref"
										name="languagePref"
										onChange={(e) =>
											setFormData({
												...formData,
												languagePref: e.target.value,
											})
										}
										disabled={submitting}
										options={Object.keys(PreferredLanguage).map(
											(x) =>
												new ListOption({
													id: x,
													label: `ENUMS.LANGUAGE.${x}`,
												})
										)}
									/>
								</div>

								<div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4">
									<UiSelect
										id="institutionId"
										placeholder="PARTICIPANTS.DASHBOARD.MODAL_CREATE.FORM_FIELDS.institutionId"
										label="PARTICIPANTS.DASHBOARD.MODAL_CREATE.FORM_FIELDS.institutionId"
										name="institutionId"
										onChange={(e) =>
											setFormData({
												...formData,
												institutionId: e.target.value,
											})
										}
										validations={[Validations.required]}
										disabled={submitting}
										options={
											institutions.data?.map(
												(x) =>
													new ListOption({
														id: x.institutionId,
														label: x.institutionName,
													})
											) || []
										}
									/>
								</div>

								<div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
									<UiInputText
										id="phoneNumber"
										label="PARTICIPANTS.DASHBOARD.MODAL_CREATE.FORM_FIELDS.phoneNumber"
										name="phoneNumber"
										prefix="+1"
										onChange={(e) =>
											setFormData({
												...formData,
												phoneNumber: e.target.value,
											})
										}
										validations={
											formData.phoneNumber ? [Validations.numbers] : []
										}
										disabled={submitting}
									/>
								</div>

								<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-5">
									<UiInputText
										id="email"
										label="PARTICIPANTS.DASHBOARD.MODAL_CREATE.FORM_FIELDS.email"
										name="email"
										onChange={(e) =>
											setFormData({ ...formData, email: e.target.value })
										}
										validations={formData.email ? [Validations.email] : []}
										disabled={submitting}
									/>
								</div>
							</div>
						</FormSection>

						{message && (
							<div className="form-message">
								<UiFieldMessage
									severity={FieldMessageSeverity.DANGER}
									label="PARTICIPANTS.DASHBOARD.MODAL_CREATE.ERROR_SUBMIT"
								/>
							</div>
						)}

						<div className="action-buttons">
							<UiButton
								id="modalBtnClose"
								label="UI_COMPONENTS.BUTTONS.CLOSE"
								className="p-button-outlined p-button-rounded"
								type="button"
								onClick={onClose}
								disabled={submitting}
							/>
							<UiButton
								id="modalBtnSubmit"
								label={
									submitting
										? "UI_COMPONENTS.BUTTONS.SAVING"
										: "UI_COMPONENTS.BUTTONS.SAVE"
								}
								className="p-button-rounded"
								type="submit"
								loading={submitting}
								onClick={handleSubmit}
								disabled={submitting || form.getState().invalid}
							/>
						</div>
					</form>
				)}
			/>
		</Dialog>
	);
};
export default ParticipantsCreateModal;
