import { useEffect, useState } from "react";
import { PageProps } from "../../../models/routing.model";
import { getSessionStorageUser } from "../../../services/session/auth.service";
import { ParticipantReminderRow } from "../../../models/pages/participants-reminder.model";
import UiTable from "../../../components/table/Table.component";
import UiModalReminder from "../../../components/modal-reminder/ModalReminder.component";
import { TableMessages } from "../../../models/components/table/table-message.model";
import {
	FieldMessageSeverity,
	UiFieldMessageProps,
} from "../../../models/components/field-message.model";
import {
	TablePagination,
	ITablePagination,
} from "../../../models/components/table/table-pagination.model";
import {
	TableColumnTemplate,
	TableDateMode,
	TableColumn,
} from "../../../models/components/table/table-column.model";
import { HelperService } from "../../../services/helpers/helper.service";
import { EndpointsService } from "../../../services/endpoints/endpoints.service";
import { AxiosError } from "axios";
import { BackendPagination } from "../../../models/pagination.model";
import { Reminder, IReminder } from "../../../models/entities/reminder.model";
import { TableTemplateButtonsEvent } from "../../../models/components/table/table-template.model";
import {
	ModalReminderMode,
	UiModalReminderProps,
} from "../../../models/components/modal-reminder.model";
import { ITableHeaderSearch } from "../../../models/components/table/table-search.model";
import {
	TableFilterFormData,
	TableFilterType,
	ITableFilter,
	ITableHeaderFilter,
} from "../../../models/components/table/table-filter.model";
import { ProviderTwilio } from "../../../models/entities/provider.model";
import { FilterService } from "../../../services/filter/filter.service";
import { List, ListOption } from "../../../models/misc.model";
import { Formatter } from "../../../services/formatter/formatter.service";
import UiButton from "../../../components/button/Button";
import { StoredCriteriaService } from "../../../services/session/stored-criteria.service";

const ParticipantsReminders = (props: PageProps) => {
	// Today's date
	const today: Date = new Date();
	today.setHours(0, 0, 0, 0);

	/* Table */

	// Columns
	const setColumns = (): TableColumn[] => {
		const columns: TableColumn[] = [
			{
				field: "kannactId",
				title: "PARTICIPANTS.REMINDERS.TABLE.COLUMNS.kannactId",
				template: TableColumnTemplate.KANNACT_ID,
			},
			{
				field: "name",
				title: "PARTICIPANTS.REMINDERS.TABLE.COLUMNS.name",
				sortable: true,
				sortingField: "firstName,lastName",
			},
			{
				field: "createdBy",
				title: "PARTICIPANTS.REMINDERS.TABLE.COLUMNS.createdBy",
				sortable: true,
			},
			{
				field: "dateOn",
				title: "PARTICIPANTS.REMINDERS.TABLE.COLUMNS.dateOn",
				sortable: true,
				template: TableColumnTemplate.DATE,
				templateOptions: {
					dateMode: TableDateMode.UTC,
					dateFormat: {
						day: "2-digit",
						month: "2-digit",
						year: "2-digit",
						timeZone: "UTC",
					},
				},
			},
			{
				field: "reason",
				title: "PARTICIPANTS.REMINDERS.TABLE.COLUMNS.reason",
				sortable: true,
			},
			{
				field: "actionsTemplate",
				template: TableColumnTemplate.BUTTONS,
				alignment: "right",
			},
		];

		if (HelperService.isAdminViewMode()) {
			const column: TableColumn = {
				field: "coachId",
				template: TableColumnTemplate.COACH,
				title: "PARTICIPANTS.REMINDERS.TABLE.COLUMNS.coachId",
				sortable: true,
			};
			columns.splice(2, 0, column);
		}

		return columns;
	};

	const columns: TableColumn[] = setColumns();

	// Rows
	const [rows, setRows] = useState<ParticipantReminderRow[]>([]);

	// Filters
	const dateOnFilter: ITableFilter = {
		field: "dateOn",
		type: TableFilterType.DATE_RANGE,
		options: {
			columnClass: "col-12 col-md-3 col-lg-2",
			props: {
				label: "PARTICIPANTS.REMINDERS.FILTERS.DATE_ON_TITLE",
			},
		},
		value: [today, today],
	};
	const coachFilter: ITableFilter = {
		field: "coachId",
		type: TableFilterType.SELECT_MULTIPLE,
		value: [`${getSessionStorageUser()?.id}`],
	};
	const [filters, setFilters] = useState<ITableHeaderFilter>(
		StoredCriteriaService.getFilters(StoredCriteriaService.KEYS["participants-reminders"]) ?? {
			filters: [dateOnFilter],
		}
	);

	// Pagination
	const [count, setCount] = useState<number>(0);
	const [pagination, setPagination] = useState<TablePagination>(
		StoredCriteriaService.getPagination(
			StoredCriteriaService.KEYS["participants-reminders"]
		) ?? {
			first: 0,
			rows: 10,
			page: 0,
			sortField: "dateOn",
			sortOrder: 1,
			search: undefined,
			filters: filters?.filters || [],
		}
	);

	// Messages
	const messages = new TableMessages();
	const [message, setMessage] = useState<UiFieldMessageProps>({
		severity: FieldMessageSeverity.INFO,
		label: messages.empty,
	});

	// Pagination
	const onPagination = async (event: TablePagination): Promise<void> => {
		const newPagination: TablePagination = {
			...pagination,
			page: event.page,
			rows: event.rows,
			sortField: event.sortField,
			sortOrder: event.sortOrder,
			first: event.first,
		};
		setPagination(newPagination);
		StoredCriteriaService.setPagination(
			StoredCriteriaService.KEYS["participants-reminders"],
			newPagination
		);

		getData(newPagination);
	};

	// Search
	const tableSearch: ITableHeaderSearch = {
		title: "PARTICIPANTS.REMINDERS.SEARCH_PLACEHOLDER",
		value: pagination.search || undefined,
		fn: (value: string | null | undefined) => {
			// Update pagination state
			const newPagination: TablePagination = {
				...pagination,
				search: value,
				page: 0,
				first: 0,
			};
			setPagination(newPagination);
			StoredCriteriaService.setPagination(
				StoredCriteriaService.KEYS["participants-reminders"],
				newPagination
			);

			// Update data
			getData(newPagination);
			getCount(newPagination);
		},
	};

	/**
	 * MODAL: Reminders
	 * - Create
	 * - Update
	 * - Delete
	 * - Resolve
	 * - Snooze
	 */

	const resolve = async (event: TableTemplateButtonsEvent): Promise<void> => {
		// Update entity
		const updatedReminder: Reminder = (event.row as ParticipantReminderRow).entity;
		updatedReminder.resolved = true;
		updatedReminder.updatedOn = new Date().toISOString();

		// Update row (on the frontend, while the endpoint updates the real data)
		setRows(
			rows.filter(
				(row: ParticipantReminderRow) =>
					row.entity.id !== (event.row as ParticipantReminderRow).entity.id
			)
		);

		// Call endpoint
		await EndpointsService.dataRetriever
			.updateReminder({
				body: {
					id: updatedReminder.id as number,
					kannactId: updatedReminder.kannactId,
					patientId: updatedReminder.patientId,
					reason: updatedReminder.reason,
					dateOn: updatedReminder.dateOn,
					resolved: updatedReminder.resolved,
					createdOn: updatedReminder.createdOn,
					updatedOn: updatedReminder.updatedOn,
				},
			})
			.finally(() => {
				getData(pagination);
				getCount(pagination);
			});
	};

	const snoozeModal = (event: TableTemplateButtonsEvent): void =>
		setModalProps({
			...modalProps,
			mode: ModalReminderMode.SNOOZE,
			entity: (event.row as ParticipantReminderRow).entity,
			isVisible: true,
		});

	const createModal = (): void =>
		setModalProps({
			...modalProps,
			mode: ModalReminderMode.CREATE,
			entity: null,
			isVisible: true,
		});

	const updateModal = (reminder: IReminder): void =>
		setModalProps({
			...modalProps,
			mode: ModalReminderMode.DETAILS,
			entity: reminder,
			isVisible: true,
		});

	const deleteModal = (reminder: IReminder): void =>
		setModalProps({
			...modalProps,
			mode: ModalReminderMode.DELETE,
			entity: reminder,
			isVisible: true,
		});

	const cancelModal = () => {
		setModalProps({ ...modalProps, isVisible: false });
	};

	// Reminder modal: close
	const completeModal = (refresh: boolean, reminder: IReminder | null): void => {
		// Update modal state
		setModalProps({ ...modalProps, isVisible: false });

		// Update row template
		if (refresh && reminder) {
			setRows(
				rows.map((row: ParticipantReminderRow) => {
					if (row.entity.id === reminder.id) {
						// Update value
						const originalEntity: IReminder = { ...row.entity, ...reminder };

						// Create new row
						row = new ParticipantReminderRow(
							originalEntity,
							resolve.bind(this),
							snoozeModal.bind(this),
							updateModal.bind(this, originalEntity),
							deleteModal.bind(this, originalEntity)
						);
					}
					return row;
				})
			);
		}

		// Refresh to get the updated data (also down the tree)
		if (refresh) {
			getData(pagination);
			getCount(pagination);
		}
	};

	// Modal reminder: state
	const [modalProps, setModalProps] = useState<UiModalReminderProps>({
		mode: ModalReminderMode.DETAILS,
		entity: null,
		isVisible: false,
		onCancel: cancelModal.bind(this),
		onComplete: completeModal.bind(this),
	});

	/**
	 * DATA
	 */

	// First time it loads
	useEffect(() => {
		// Get data
		getData(pagination);
		getCount(pagination);

		// Get filters
		if (
			!StoredCriteriaService.getFilters(StoredCriteriaService.KEYS["participants-reminders"])
				?.filters &&
			HelperService.isAdminViewMode()
		) {
			getFilters();
		}
	}, []);

	const formatPagination = (event: TablePagination): TablePagination => {
		// Update pagination object
		const updatedEvent: ITablePagination = {
			...event,
			sortField: HelperService.compoundSortField(columns, event),
		};

		// Coach view: add coachId filter by default
		if (!HelperService.isAdminViewMode())
			updatedEvent.filters = [...((filters?.filters as ITableFilter[]) || []), coachFilter];

		if (updatedEvent.filters && FilterService.hasSelectedFilters(updatedEvent.filters)) {
			updatedEvent.filters = updatedEvent.filters.map((item) => {
				if (item.field === "dateOn" && item.value) {
					const minDate = item.value[0]
						? Formatter.dateUTCWithoutTime(item.value[0] as Date)?.toISOString()
						: undefined;
					const maxDate = item.value[1]
						? Formatter.dateUTCWithoutTime(item.value[1] as Date)?.toISOString()
						: undefined;

					return { ...item, value: [minDate, maxDate] };
				}
				return item;
			});
		}

		return updatedEvent;
	};

	// Get data: participants
	const getData = async (event: TablePagination): Promise<void> => {
		// Reset previous data
		setRows([]);

		// Set the loading spinner
		setMessage({ severity: FieldMessageSeverity.LOADING, label: messages.loading });

		await EndpointsService.dataRetriever
			.getReminders({
				config: {
					params: new BackendPagination(formatPagination(event)),
				},
			})
			.then((response: IReminder[]) => {
				if (response?.length > 0) {
					setRows(
						response.map(
							(item) =>
								new ParticipantReminderRow(
									item,
									resolve.bind(this),
									snoozeModal.bind(this),
									updateModal.bind(this, item),
									deleteModal.bind(this, item)
								)
						)
					);
				} else {
					setMessage({ severity: FieldMessageSeverity.INFO, label: messages.empty });
				}
			})
			.catch((error: AxiosError) =>
				setMessage({ severity: FieldMessageSeverity.DANGER, label: messages.error })
			);
	};

	const getCount = async (event: TablePagination): Promise<void> => {
		await EndpointsService.dataRetriever
			.getRemindersCount({
				config: { params: new BackendPagination(formatPagination(event)) },
			})
			.then((response: number) => {
				if (response || response === 0) setCount(response);
			})
			.catch((error: AxiosError) => {
				console.log("Participant Reminders: Error getting the count");
			});
	};

	/**
	 * FILTERS
	 */

	// Filters: get options (call a backend endpoint to retreive the coaches list)
	const getFilters = async (): Promise<void> => {
		await EndpointsService.dataRetriever
			.getProvidersTwilio()
			.then((response: ProviderTwilio[]) => {
				// If there is data --> Map it
				if (response?.length > 0) mapFilters(response);
			})
			.catch((error: AxiosError) => {
				console.error("Participants - Couldn't get the providers list");
			});
	};

	// Filters: submit
	const submitFilters = (value: TableFilterFormData): void => {
		// Update filters state
		const newFilters = FilterService.updateFiltersValues(
			(filters as ITableHeaderFilter).filters,
			value
		);

		setFilters({ ...filters, filters: newFilters });

		// Update table message
		setMessage({ severity: FieldMessageSeverity.LOADING, label: messages.filter });

		// Update pagination
		const newPagination: TablePagination = {
			...pagination,
			filters: newFilters,
			page: 0,
			first: 0,
		};
		setPagination(newPagination);

		StoredCriteriaService.set(
			StoredCriteriaService.KEYS["participants-reminders"],
			newPagination,
			{ ...filters, filters: newFilters }
		);

		// Get data
		getData(newPagination);
		getCount(newPagination);
	};

	// Filters: map
	const mapFilters = (providers: ProviderTwilio[]): void => {
		// Map backend entity
		const providersList: List = providers.map(
			(item) =>
				new ListOption({
					id: item.coachId,
					label: `${item.firstName} ${item.lastName}`,
				})
		);

		// Update filters
		const updatedFilters: ITableFilter[] = [
			...(filters?.filters as ITableFilter[]),
			{
				field: "coachId",
				type: TableFilterType.SELECT_MULTIPLE,
				options: {
					columnClass: "col-12 col-md-3 col-lg-3",
					props: {
						label: "PARTICIPANTS.REMINDERS.FILTERS.COACH_TITLE",
						options: providersList,
						filter: true,
					},
				},
				value: [],
			},
		];

		setFilters({
			...filters,
			filters: updatedFilters,
		});
		StoredCriteriaService.setFilters(StoredCriteriaService.KEYS["participants-reminders"], {
			...filters,
			filters: updatedFilters,
		});
	};

	return (
		<>
			{/* Table */}
			<UiTable
				dataKey="id"
				useAsCard={true}
				title={
					HelperService.isAdminViewMode()
						? "PARTICIPANTS.REMINDERS.TABLE.TITLE_ADMIN"
						: "PARTICIPANTS.REMINDERS.TABLE.TITLE_COACH"
				}
				customHeader={
					<UiButton
						type="button"
						className="p-button-rounded p-button-sm"
						icon={"pi pi-plus"}
						label="PARTICIPANTS.REMINDERS.BUTTON_CRUD_CREATE"
						onClick={createModal}
					/>
				}
				columns={columns}
				value={rows}
				message={message}
				isServerPaginated={true}
				pagination={pagination}
				paginationFn={onPagination}
				totalRecords={count}
				search={tableSearch}
				filter={filters}
				filterFn={submitFilters}
			/>

			{/* Reminder modal */}
			{modalProps.isVisible && <UiModalReminder {...modalProps}></UiModalReminder>}
		</>
	);
};
export default ParticipantsReminders;
